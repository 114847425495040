import React, {useState} from 'react';
import {Box, List, ListItemButton, ListItemText, Typography, Drawer, IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import sdlogo from '../../../images/sdlogo.png';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import useAuthStore from '../../../store/authStore';
import useAdminStore from '../../../store/adminStore';
import useLeaderStore from '../../../store/leaderStore';
import {useNavigate} from 'react-router-dom';
import {DeleteOutline} from '@mui/icons-material';
import Modal from '../../../components/common/Modal';
import { deleteUser } from '../../../api';

const sidebarOptions = [
  // {
  //   id: 1,
  //   path: "/admin-dashboard",
  //   icon: (path) => <HomeIcon sx={{ mr: 1, color: path === "/admin-dashboard" ? "white" : "#8A8A8A" }} />,
  //   title: "Dashboard",
  //   type: "list-item"
  // },
  {
    id: 2,
    path: '/admin-dashboard/groups',
    icon: path => <GroupsIcon sx={{mr: 1, color: path === '/admin-dashboard/groups' ? 'white' : '#8A8A8A'}} />,
    title: 'Groups',
    type: 'list-item'
  },
  {
    id: 3,
    path: '/admin-dashboard/leaders',
    icon: path => <GroupIcon sx={{mr: 1, color: path === '/admin-dashboard/leaders' ? 'white' : '#8A8A8A'}} />,
    title: 'Leaders',
    type: 'list-item'
  },
  {
    id: 4,
    path: '/admin-dashboard/disciples',
    icon: path => <PersonIcon sx={{mr: 1, color: path === '/admin-dashboard/disciples' ? 'white' : '#8A8A8A'}} />,
    title: 'Disciples',
    type: 'list-item'
  },
  {
    id: 5,
    path: '/admin-dashboard/admins',
    icon: path => <PersonIcon sx={{mr: 1, color: path === '/admin-dashboard/admins' ? 'white' : '#8A8A8A'}} />,
    title: 'Admins',
    type: 'list-item'
  },
  {
    id: 6,
    path: '/admin-dashboard/promotions',
    icon: path => <GroupsIcon sx={{mr: 1, color: path === '/admin-dashboard/promotions' ? 'white' : '#8A8A8A'}} />,
    title: 'Promotions',
    type: 'list-item'
  },
  {
    id: 7,
    path: '/admin-dashboard/reports',
    icon: path => <DescriptionIcon sx={{mr: 1, color: path === '/admin-dashboard/reports' ? 'white' : '#8A8A8A'}} />,
    title: 'Reports',
    type: 'list-item'
  },
  {
    id: 8,
    type: 'seperator'
  },
  {
    id: 9,
    path: '/admin-dashboard/settings',
    icon: path => <SettingsIcon sx={{mr: 1, color: path === '/admin-dashboard/settings' ? 'white' : '#8A8A8A'}} />,
    title: 'Settings',
    type: 'list-item'
  }
];

function Sidebar({totalDisciples}) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const {logoutUser, user} = useAuthStore(state => state);
  const {clearAdminData} = useAdminStore(state => state);
  const {clearLeaderData} = useLeaderStore(state => state);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutUserHandler = () => {
    logoutUser();
    clearLeaderData();
    clearAdminData();
  };

  const handleDeleteUser = async() => {
    try {
      setLoading(true);
      const res = await deleteUser(user?.id);
      if (res?.data?.status === 200) {
        setModalOpen(false);
        logoutUserHandler();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const drawerContent = (
    <Box bgcolor="#222" color="white" display="flex" flexDirection="column" p={2} height="100vh" overflow="auto">
      <Box lineHeight="50px">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            marginBottom: 30
          }}
        >
          <img src={sdlogo} alt="" width={25} height={25} />
          <span>Sustainable Discipleship</span>
        </div>
        <Box
          borderRadius="10px"
          py={1}
          textAlign="center"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{
            background: 'linear-gradient(135deg, #d0373b, #6d0407)',
            cursor: 'pointer'
          }}
          onClick={() => {
            navigate('/admin-dashboard/disciples');
          }}
        >
          <Box width="fit-content" position="relative" display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h2">{totalDisciples} </Typography>
            <InfoOutlinedIcon style={{position: 'absolute', top: 0, right: -20}} />
          </Box>
          <Typography variant="subtitle1">Total Disciples</Typography>
        </Box>
        <List sx={{color: '#8A8A8A'}}>
          {sidebarOptions.map(option => {
            if (option.type === 'seperator') {
              return <hr key={option.id} style={{width: '90%', marginLeft: '6px', opacity: '50%', margin: '10px 0'}} />;
            } else {
              return (
                <ListItemButton
                  key={option.id}
                  className="mb-2"
                  onClick={() => {
                    navigate(option.path);
                  }}
                  sx={{
                    marginBottom: '10px',
                    background: pathName === option.path ? 'linear-gradient(135deg, #d0373b, #6d0407)' : 'none',
                    borderRadius: '30px',
                    ':hover': {
                      bgcolor: '#d32f2f'
                    }
                  }}
                >
                  {option.icon(option.path)}
                  <ListItemText
                    primary={option.title}
                    primaryTypographyProps={{
                      style: {
                        color: pathName === option.path ? '#fff' : '#8A8A8A',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                </ListItemButton>
              );
            }
          })}
          {/* TODO: uncomment this code for delete user */}
          <ListItemButton
            sx={{
              borderRadius: '30px',
              marginBottom: "10px",
              ':hover': {
                bgcolor: '#d32f2f'
              }
            }}
            onClick={() => setModalOpen(true)}
          >
            <DeleteOutline sx={{mr: 1, color: 'white'}} />
            <ListItemText
              primary="Delete My Account"
              primaryTypographyProps={{
                style: {
                  color: '#8A8A8A',
                  fontWeight: 'bold'
                }
              }}
            />
          </ListItemButton>
          <ListItemButton
            sx={{
              borderRadius: '30px',
              ':hover': {
                bgcolor: '#d32f2f'
              }
            }}
            onClick={() => logoutUserHandler()}
          >
            <LogoutIcon sx={{mr: 1, color: 'white'}} />
            <ListItemText
              primary="Log Out"
              primaryTypographyProps={{
                style: {
                  color: '#8A8A8A',
                  fontWeight: 'bold'
                }
              }}
            />
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          display: {md: 'none'},
          position: 'absolute',
          top: 16,
          left: 16
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: {xs: 'block', sm: 'block', md: 'none'},
          '& .MuiDrawer-paper': {boxSizing: 'border-box', width: 250}
        }}
      >
        {drawerContent}
      </Drawer>
      <Box
        sx={{
          display: {xs: 'none', sm: 'none', md: 'block'},
          width: {md: 250}
        }}
      >
        {drawerContent}
      </Box>

      <Modal
          open={modalOpen}
          setOpen={setModalOpen}
          title="Delete Account"
          backgroundColor='white'
          titleStyles={{color: 'black',fontWeight:'bold'}}
          bodyStyles={{color: 'black'}}
          buttons={[
            {name: 'Close', onClick: () => setModalOpen(false)},
            {name: 'Delete', onClick: () => handleDeleteUser(), loading: loading}
          ]}
        >
          <Typography sx={{color: 'black'}}>Are you sure you want to delete your account? All associated data will be removed from the application soon, and you will be logged out immediately.</Typography>
        </Modal>
    </>
  );
}

export default Sidebar;
